import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Software/macOS/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision v3 for macOS",
  "path": "/Software/macOS/InstarVision/Advanced/Export/",
  "dateChanged": "2018-01-25",
  "author": "Mike Polinowski",
  "excerpt": "Validate your License Code online or by Email",
  "image": "../../P_SearchThumb_InstarVision_Windows.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Windows.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='InstarVision v3 for macOS' dateChanged='2018-01-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='The InstarVision Surveillance Center is an IP camera software which can support 64 cameras at the same time, including Network cameras, USB cameras remotely accessed secondary installations of the software.' image='/images/Search/P_SearchThumb_InstarVision_Windows.png' twitter='/images/Search/P_SearchThumb_InstarVision_Windows.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/macOS/InstarVision/Advanced/Export/' locationFR='/fr/Software/macOS/InstarVision/Advanced/Export/' crumbLabel="Export" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0e777c1473a4459d1836ab73bfab3605/29114/Banner_en-InstarVision2-0_Advanced.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAe0lEQVQI143HsQrCMBSF4fsq+nwObordHZrrFHERJ/sqLq4RWnF3cGxSQwzlF1pn6YGPwy/0CaKHGCB18G7HJjN11lrWmwJyRIwLqPPorUPvGW1Gpvlg6vRXWSd2j55iq6yWC0rnETm9kOOT4SuPnH+qqQLz/YXZ4Tr0F9aiwNov85E3AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e777c1473a4459d1836ab73bfab3605/e4706/Banner_en-InstarVision2-0_Advanced.avif 230w", "/en/static/0e777c1473a4459d1836ab73bfab3605/d1af7/Banner_en-InstarVision2-0_Advanced.avif 460w", "/en/static/0e777c1473a4459d1836ab73bfab3605/7f308/Banner_en-InstarVision2-0_Advanced.avif 920w", "/en/static/0e777c1473a4459d1836ab73bfab3605/e1c99/Banner_en-InstarVision2-0_Advanced.avif 1380w", "/en/static/0e777c1473a4459d1836ab73bfab3605/76ea5/Banner_en-InstarVision2-0_Advanced.avif 1840w", "/en/static/0e777c1473a4459d1836ab73bfab3605/f696c/Banner_en-InstarVision2-0_Advanced.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0e777c1473a4459d1836ab73bfab3605/a0b58/Banner_en-InstarVision2-0_Advanced.webp 230w", "/en/static/0e777c1473a4459d1836ab73bfab3605/bc10c/Banner_en-InstarVision2-0_Advanced.webp 460w", "/en/static/0e777c1473a4459d1836ab73bfab3605/966d8/Banner_en-InstarVision2-0_Advanced.webp 920w", "/en/static/0e777c1473a4459d1836ab73bfab3605/445df/Banner_en-InstarVision2-0_Advanced.webp 1380w", "/en/static/0e777c1473a4459d1836ab73bfab3605/78de1/Banner_en-InstarVision2-0_Advanced.webp 1840w", "/en/static/0e777c1473a4459d1836ab73bfab3605/882b9/Banner_en-InstarVision2-0_Advanced.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e777c1473a4459d1836ab73bfab3605/81c8e/Banner_en-InstarVision2-0_Advanced.png 230w", "/en/static/0e777c1473a4459d1836ab73bfab3605/08a84/Banner_en-InstarVision2-0_Advanced.png 460w", "/en/static/0e777c1473a4459d1836ab73bfab3605/c0255/Banner_en-InstarVision2-0_Advanced.png 920w", "/en/static/0e777c1473a4459d1836ab73bfab3605/b1001/Banner_en-InstarVision2-0_Advanced.png 1380w", "/en/static/0e777c1473a4459d1836ab73bfab3605/161ec/Banner_en-InstarVision2-0_Advanced.png 1840w", "/en/static/0e777c1473a4459d1836ab73bfab3605/29114/Banner_en-InstarVision2-0_Advanced.png 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0e777c1473a4459d1836ab73bfab3605/c0255/Banner_en-InstarVision2-0_Advanced.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "side-navigation---import--export-system-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#side-navigation---import--export-system-settings",
        "aria-label": "side navigation   import  export system settings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Side Navigation - Import & Export System Settings`}</h2>
    <h3 {...{
      "id": "import",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#import",
        "aria-label": "import permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Import`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dc61d0da88ce1fb22a3f62fae5daa0db/3dde1/Image049_Import.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAADcUlEQVQozx2QbVCTBQCA34PjW/kcH4IbIwYDCoT4Gh8DFh+JILDJaMKoQCngFDVFJiRi0pnQMk+EKSnKdWc/Cv5oZ/1J+wFSd0itA+/MiosLNUMthmyy96l8/j73/HmEHFW6KJNGECGViSHhUuTySF6IkhOvVJCaFEeBppJirYnNuk7K9e9SaThMmf4Quu2dlFeb2H3AzC93f2b00iiTExMI4SFBuAiCmCNzpz7JHZm3QLyfQICngCAIKBXZVNZ8xJuvm2lqtvBWi4W9B0do2TOAof4YrXv7GRsbw2w2c+H8eYSXk2LFwtRIduaFiwNlGzlZLuFtjYTUuFAk/p5kZWkwdfXxQUcXZtNhdrX10nuoD319N/va2mnauYuBM4OMj48zPDyMkJIcT7M+h6FtsYwUeTF7bYjZO3f57Y6VOestbt+eY/HeQ+Z/XeDP3+dZWHzEjbFxGms72fqaifb97VjOWrg4OsqQxYIQGhJCQbIvl3dLudToy8zUFRwiPH7yD441EVEEp9PJs7U1/l5ewS7C9a9v0NjQS77uOPod3fT1HuXc6Y85cawHQR4RRnCwKxVbwthf5MXktc+YX1ziyaMlHA4Hdrsdm83G05UV7Kur/M/1zy/TYWwkK/8N9Ds6ODB5j4aJJY58fx8hJVHJxtAA4hRSlFHhfPXl1efRss3G8lMHNvsaNoeTxYePWfjj/nN3tLuTdW4uBPv5kpeXz9VbU4x99y3fzM4gxCkVKGOjyc7OJDd9EyeOmPh09CKWoTNc+OQclsEBBk5+yKn+47z/Xg9nT/WztbSEQB8PPDxdycjM4IvpaUZuTnLFakWIkstQKmPQVlWwuTAPQ1khDYYqwsMkBEkC8F3vjYcg4C0IeLm5EuS3ngRFNPKYRHw2+JKRnUXT9CqlNx28Y7UjKGNjRFm0jDpjhdjWWktKWiINNcVkpr6EpqwAlSYHib8/AZIwpNIQ9Not9BzcQ+ErRURHSlHnqpl5sMzUAxs//WVDiIlRoH1VLbbWl1JUksWmzERi5SGkpySgb6jBZ50Pbi6uuHt4ERgYRGPddkYGT1OsSmODlwuFGjWsPQOcIDoRtDq9U1u5jSyVSmze14JOryPhxSRy1WqqdFVU1xnJ15SQnZ6GOrcAo8FIV7uJhmotRapkjLUGfvjRyuzsHNb/Hv4LDccqKEXCS4UAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dc61d0da88ce1fb22a3f62fae5daa0db/e4706/Image049_Import.avif 230w", "/en/static/dc61d0da88ce1fb22a3f62fae5daa0db/d1af7/Image049_Import.avif 460w", "/en/static/dc61d0da88ce1fb22a3f62fae5daa0db/7f308/Image049_Import.avif 920w", "/en/static/dc61d0da88ce1fb22a3f62fae5daa0db/7a54e/Image049_Import.avif 1018w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dc61d0da88ce1fb22a3f62fae5daa0db/a0b58/Image049_Import.webp 230w", "/en/static/dc61d0da88ce1fb22a3f62fae5daa0db/bc10c/Image049_Import.webp 460w", "/en/static/dc61d0da88ce1fb22a3f62fae5daa0db/966d8/Image049_Import.webp 920w", "/en/static/dc61d0da88ce1fb22a3f62fae5daa0db/5304f/Image049_Import.webp 1018w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dc61d0da88ce1fb22a3f62fae5daa0db/81c8e/Image049_Import.png 230w", "/en/static/dc61d0da88ce1fb22a3f62fae5daa0db/08a84/Image049_Import.png 460w", "/en/static/dc61d0da88ce1fb22a3f62fae5daa0db/c0255/Image049_Import.png 920w", "/en/static/dc61d0da88ce1fb22a3f62fae5daa0db/3dde1/Image049_Import.png 1018w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dc61d0da88ce1fb22a3f62fae5daa0db/c0255/Image049_Import.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`It is recommended to export your software settings (see below) once you have InstarVision up and running. If you have to reinstall the software, just go to the Import menu, select your exported file - the default name is instarvision2.cfg - and click ok. Your software will be set again after a reboot.`}</p>
    <h3 {...{
      "id": "export",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#export",
        "aria-label": "export permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Export`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/481b194f2d19547f3024e9bc429e7123/3dde1/Image049_Export.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAADbUlEQVQozx3Rb0zUBQDG8V+UN84hwvSAju5A/h2gEojCHccJBGgmcXByenp3FQjGIXAwOzlAWQFbZrvalOIa05TVXLmOWptbrTVrim5mWjeJphVOCLrhDC7kOOD3bfJsnzfPi+fNI2jV20SlIpZYhVKMkiuIj48jYUM8aaoksjNSKSzSU1rp5EVDB2XGY+hNx9ltbMewv4OyKidNb7j48497DJ4f5NrwMII8ah0hgiBqlRKsGRKUqwXS1gpEhgoIgoAqKQ/93vd47RUXdTY3h2xuWo5+jM3eh8naQ0PLu3g8HlwuF2fPnEHYkpEiFmfHUbtdLvbtjuX9svUcKlpHdmo06yNC0WiKcHae5ERbJy7ncRqbe+ltP4nR2kVrs4O62kb6PviQoaEhBgYGELIy06g3aunfk8LZEil3LvVx5+49xu56GfHe4vfRUSb/meb+2AS+B/cZn3rE5YsXqTnQwcv7nDiOOHB/5Obc4CD9bjdCdFQUhZnhXGhScL4mnJvDX7KwDDOzfpZEWBZFgsHgikAwiAj8+O331FT3UmB4G+PBLt7p7cZ9+hQneroR4mNjkMmepvylGI6USLl66VPGfTP4Z//lSURRZHFxccXS4uJK94PnAm2WavIKXsV4sI2Oq39x+MoD3rw2hpC1WcVz0ZGkJilQJcj5yvM5szOz+Hw+5ubmCAQCK4ILCzx+PLcy2NPVSdiqEGRrw9FtL8Dp9VN7e563RucRUlVJqFIS0WrVqLPS+XroC/z/zTE+Ps7k5BQPp6eZ8fvxPXzExKSPQGABp8PBGslThEqfISc3hxs/fcOVYQ8/3/oOYUO8EpUqGUOFnpKCPLo7j+L57BNa7E3YW+283mCj1mJmr8mMcb8Ve3MjVfpy5HEqwuQR5Gg0HLv5N/XXJ+i+PYWgSkkWlYlKzJZy0X7YzMbNyRh35ZKeomSLLov07E1IBAGJREpkRCg7X9DSbrdRUlxKYpwCXb6O6755LvuC3JheQEhOTqJyp05ssO6ieIeGTPXzpCbEkJu9iX21JsLD1xC6SoJ0dRgymYw6q5lz/X2UqrfyrDSE4iIdLD05axnEJYRKg3G5Ur8HjVot1rfaMBgNpG/MIF+no8JQQZXZQkHRDvK2bUWXX4jFZKHT4aS6qpISdSaWAyZ++dXLyMhveL1e/gfuwDuYQZ54KgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/481b194f2d19547f3024e9bc429e7123/e4706/Image049_Export.avif 230w", "/en/static/481b194f2d19547f3024e9bc429e7123/d1af7/Image049_Export.avif 460w", "/en/static/481b194f2d19547f3024e9bc429e7123/7f308/Image049_Export.avif 920w", "/en/static/481b194f2d19547f3024e9bc429e7123/7a54e/Image049_Export.avif 1018w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/481b194f2d19547f3024e9bc429e7123/a0b58/Image049_Export.webp 230w", "/en/static/481b194f2d19547f3024e9bc429e7123/bc10c/Image049_Export.webp 460w", "/en/static/481b194f2d19547f3024e9bc429e7123/966d8/Image049_Export.webp 920w", "/en/static/481b194f2d19547f3024e9bc429e7123/5304f/Image049_Export.webp 1018w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/481b194f2d19547f3024e9bc429e7123/81c8e/Image049_Export.png 230w", "/en/static/481b194f2d19547f3024e9bc429e7123/08a84/Image049_Export.png 460w", "/en/static/481b194f2d19547f3024e9bc429e7123/c0255/Image049_Export.png 920w", "/en/static/481b194f2d19547f3024e9bc429e7123/3dde1/Image049_Export.png 1018w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/481b194f2d19547f3024e9bc429e7123/c0255/Image049_Export.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once you set up the software and the Surveillance Center is in operation, it is recommended to export your settings for a later easy recovery.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      